<template>

      <v-btn
        @click="toggleTransportMode"
        icon
        class="my-0 py-0"
        :style="btnStyle">
        <v-img
          v-if="enabled"
          :src="iconEnabledURL"
          alt=""
          contain
          style="height: 22px !important; width: 22px !important;"/>
        <v-img
          v-else
          :src="iconDisabledURL"
          alt=""
          contain
          style="height: 22px !important; width: 22px !important;"/>
      </v-btn>
</template>

<script>
export default {
  name: "IconModeLanding",

  props: {
    mode: {type: Object, default: () => null}
  },

  data() {
    return {

    }
  },

  created() {
  },

  methods: {
    toggleTransportMode() {
      //console.log(!this.enabled);
      this.enabled = !this.enabled;
    }
  },

  computed: {

    iconDisabledURL() {
      return require(`@/assets/icons/modes/${this.mode.icon_disabled}`);
    },
    iconEnabledURL() {
      return require(`@/assets/icons/modes/${this.mode.icon_enabled}`);
    },

    btnStyle() {

      let style = "width: 50px !important; height: 50px !important;";

      if (this.enabled)
        return `${style} background-color: ${this.mode.color};`;

      return `${style} border: 1px solid ${this.mode.color};`;
    },

    enabled: {

      get() {
        return this.$store.getters['modes/isEnabled'](this.mode.name)
      },

      set(val) {
        this.$store.commit("modes/toggleMode", {mode: this.mode.name, value: val});
        /*
        if (val === true) {
          this.$store.dispatch("modes/getTransportsAround", {
            modes: [this.mode.name],
            keepOldData: true,
            jwt: this.$session.get('jwt'),
            messages: {
              ERROR_MODE_NOT_LOADED: this.$t('viewer.errors.MODES_NOT_LOADED')
            }
          });
        } else {
          this.$store.commit("modes/deleteModeFromPoints", this.mode.name);
        }*/
      }
    }
  },
}
</script>

<style scoped>

</style>